export default {
  user: {
    username: '',
    uid: '',
    email: '',
    phone: '',
    firstname: '',
    lastname: '',
    description: '',
    avatar: '',
    groups: {},
    kontrahent: {},
    token: '',
    fingerprint: '',
    fingerprintcomponents: {}
  }
}
