/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding initialization code.
 * Use "quasar new plugin <name>" and add it there.
 * One plugin per concern. Then reference the file(s) in quasar.conf.js > plugins:
 * plugins: ['file', ...] // do not add ".js" extension to it.
 **/


import Vue from 'vue'

import {Quasar,QActionSheet,QAjaxBar,QAutocomplete,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnGroup,QBtnToggle,QCard,QCardTitle,QCardMain,QCardMedia,QCardSeparator,QCardActions,QCarousel,QCarouselSlide,QCarouselControl,QCheckbox,QChip,QCollapsible,QDatetime,QEditor,QField,QIcon,QInput,QItem,QItemMain,QItemSeparator,QItemSide,QItemTile,QLayout,QLayoutDrawer,QLayoutHeader,QList,QListHeader,QModal,QModalLayout,QPage,QPageContainer,QPageSticky,QPagination,QPopover,QRadio,QRange,QRouteTab,QScrollArea,QSearch,QSelect,QSlider,QStep,QStepper,QStepperNavigation,QTab,QTable,QTableColumns,QTabPane,QTabs,QTd,QTh,QToggle,QToolbar,QToolbarTitle,QTooltip,QTr,QUploader,Ripple,CloseOverlay,ActionSheet,AddressbarColor,Dialog,Notify,Loading,LocalStorage} from 'quasar'


Vue.use(Quasar, { config: {},components: {QActionSheet,QAjaxBar,QAutocomplete,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnGroup,QBtnToggle,QCard,QCardTitle,QCardMain,QCardMedia,QCardSeparator,QCardActions,QCarousel,QCarouselSlide,QCarouselControl,QCheckbox,QChip,QCollapsible,QDatetime,QEditor,QField,QIcon,QInput,QItem,QItemMain,QItemSeparator,QItemSide,QItemTile,QLayout,QLayoutDrawer,QLayoutHeader,QList,QListHeader,QModal,QModalLayout,QPage,QPageContainer,QPageSticky,QPagination,QPopover,QRadio,QRange,QRouteTab,QScrollArea,QSearch,QSelect,QSlider,QStep,QStepper,QStepperNavigation,QTab,QTable,QTableColumns,QTabPane,QTabs,QTd,QTh,QToggle,QToolbar,QToolbarTitle,QTooltip,QTr,QUploader},directives: {Ripple,CloseOverlay},plugins: {ActionSheet,AddressbarColor,Dialog,Notify,Loading,LocalStorage} })
